import { Link } from "gatsby";
import React from "react";
import useMainStore from "../stores";
import useAuthStore from "../stores/auth";

const Sidebar = () => {
  const mainStore = useMainStore();
  const { user, isLoggedIn } = useAuthStore();

  return (
    <>
      <aside
        onClick={(e) => {
          e?.stopPropagation();
        }}
        className="p-5 flex flex-col h-full overflow-y-auto md:px-10 transition-all duration-200"
      >
        <div className="min-w-[250px] md:w-96 mx-auto font-bricolage">
          <div className="my-10">
            <button
              onClick={() => {
                mainStore.toggleSidebar();
              }}
              className="navbar-toggle cursor-pointer flex-col gap-[4px] w-[40px] h-[40px] border-black border rounded-full flex items-center justify-center"
            >
              <div
                className="w-3 h-[2px] bg-black rotate rounded"
                style={{ transform: "translateY(3px) rotate(45deg)" }}
              ></div>
              <div
                className="w-3 h-[2px] bg-black rotate rounded"
                style={{ transform: "translateY(-3px) rotate(-45deg)" }}
              ></div>
            </button>
          </div>
          <div className="nav-items mt-[2.5rem] flex-col flex md:text-xl text-base text-textColor">
            <Link
              onClick={() => {
                mainStore.toggleSidebar();
              }}
              className="flex items-center gap-3 rounded-[4px] px-[10px] py-[.6rem] cursor-pointer hover:bg-[#F5F7F9] duration-400 my-[2px]"
              to="/stories"
            >
              Stories
            </Link>
            <Link
              onClick={() => {
                mainStore.toggleSidebar();
              }}
              className="flex items-center gap-3 rounded-[4px] px-[10px] py-[.6rem] cursor-pointer hover:bg-[#F5F7F9] duration-400 my-[2px]"
              to="/videos"
            >
              Videos
            </Link>
            {!user && !isLoggedIn && (
              <>
                <Link
                  onClick={() => {
                    mainStore.toggleSidebar();
                  }}
                  className="flex items-center gap-3 rounded-[4px] px-[10px] py-[.6rem] cursor-pointer hover:bg-[#F5F7F9] duration-400 my-[2px]"
                  to="/auth/login"
                >
                  Login
                </Link>
                <Link
                  onClick={() => {
                    mainStore.toggleSidebar();
                  }}
                  className="flex items-center gap-3 rounded-[4px] px-[10px] py-[.6rem] cursor-pointer hover:bg-[#F5F7F9] duration-400 my-[2px]"
                  to="/auth/signup"
                >
                  Signup
                </Link>
              </>
            )}
            <Link
              onClick={() => {
                mainStore.toggleSidebar();
              }}
              to="/share"
              className="flex items-center gap-3 rounded-[4px] px-[10px] py-[.6rem] cursor-pointer hover:bg-[#F5F7F9] duration-400 my-[2px]"
            >
              Share your story
            </Link>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
