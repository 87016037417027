import { create } from "zustand";

const useMainStore = create((set) => ({
  isSidebarOpened: false,
  toggleSidebar(payload) {
    if (payload !== undefined && typeof payload === "boolean") {
      set(() => ({
        isSidebarOpened: payload,
      }));
      return;
    }
    set((state) => ({
      isSidebarOpened: !state.isSidebarOpened,
    }));
  },
}));

export default useMainStore;
