import React from "react";
import Logo from "../images/logo.png";
const UniversalLoader = () => {
  return (
    <main className="fixed w-full h-full top-0 left-0 bottom-0 right-0 flex items-center justify-center">
      <img src={Logo} className="w-28" alt="Logo" />
    </main>
  );
};

export default UniversalLoader;
