import React, { useEffect, useState } from "react";
import Header from "../../header";
import { Link } from "gatsby";
import axios from "../../../service/axios";
import { toast } from "react-toastify";
import useAuthStore from "../../../stores/auth";
import UniversalLoader from "../../universal-loader";

const isBrowser = typeof window !== "undefined";

const Login = () => {
  const {
    isGettingUser,
    fetchedInitialUser,
    isLoggedIn,
    user,
    getUserDetails,
  } = useAuthStore();

  useEffect(() => {
    if (!user && !fetchedInitialUser && !isGettingUser) {
      getUserDetails();
    }
  }, []);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const loginUser = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const { data } = await axios.post("/auth/login", {
        email: formData.email,
        password: formData.password,
      });
      if (data?.status !== "success") {
        return toast.error(data?.message);
      }

      toast.success(data?.message || "Login successful");

      setTimeout(() => {
        if (typeof window !== "undefined") {
          window.location.href = "/profile";
        }
      }, 3000);
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (isBrowser) {
      if (isLoggedIn && user && !isGettingUser) {
        window.location.href = "/profile";
      }
    }
  }, [isLoggedIn, isGettingUser, user]);
  useEffect(() => {
    // Set the color to black when the component mounts
    document.body.style.backgroundColor = "#1E1E1E";
    document.title = "Login";

    // Return a cleanup function that sets the color to white when the component unmounts
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, []);

  return (
    <>
      {isGettingUser || user || isLoggedIn || (!fetchedInitialUser && !user) ? (
        <UniversalLoader />
      ) : (
        <>
          <Header />

          <div>
            <div className="max-w-7xl mx-auto p-5">
              <div className="w-full flex md:flex-row flex-col items-start justify-between gap-4 md:mt-20 mt-10">
                <div className="max-w-3xl flex flex-col gap-7">
                  <h3 className=" font-bricolage text-gray-200 text-base">
                    Sign in
                  </h3>
                  <h1 className="text-white md:text-5xl text-4xl max-w-[500px]">
                    Hidden Histories, Shared Safely
                  </h1>
                  <p className=" text-gray-200 md:text-xl text-base font-bricolage max-w-[596px]">
                    Discover a sanctuary for untold tales, where anonymity meets
                    authenticity. Our platform empowers voices to break free
                    from silence, forging connections through the untold.
                  </p>
                </div>
                <div className="w-full md:max-w-[360px]">
                  <form
                    onSubmit={loginUser}
                    className="flex flex-col gap-4 w-full"
                  >
                    <div>
                      <input
                        type="email"
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                        value={formData.email}
                        className="rounded-lg px-3 py-2 font-normal w-full border text-base border-gray-300 text-gray-500 font-bricolage"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div>
                      <input
                        onChange={(e) =>
                          setFormData({ ...formData, password: e.target.value })
                        }
                        value={formData.password}
                        type="password"
                        className="rounded-lg px-3 py-2 font-normal w-full border text-base border-gray-300 text-gray-500 font-bricolage"
                        placeholder="Enter password"
                      />
                    </div>

                    <div className="flex items-center gap-3 md:flex-row flex-col">
                      <button
                        disabled={
                          isLoading || !formData.email || !formData.password
                        }
                        type="submit"
                        className="bg-black text-white px-5 py-2 text-base rounded-lg border border-black md:w-auto w-full disabled:opacity-70 cursor-pointer disabled:cursor-not-allowed"
                      >
                        Sign in
                      </button>
                      <Link
                        to="/auth/forgot-password"
                        className="text-white font-inter text-sm"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <div>
                      <p className="text-base font-inter text-[#919191]">
                        Dont have an account?&nbsp;
                        <a
                          href="/auth/signup"
                          className=" font-bricolage font-medium text-base text-white"
                        >
                          Signup
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;

export const Head = () => {
  return <title>Login</title>;
};
