import React, { useEffect } from "react";
import Logo from "../images/logo.png";
import { Link } from "gatsby";
import Sidebar from "./sidebar";
import useMainStore from "../stores";
import useAuthStore from "../stores/auth";

const Header = () => {
  const mainStore = useMainStore();
  const { isSidebarOpened } = mainStore;
  const { isLoggedIn, user } = useAuthStore();

  return (
    <>
      <header className="bg-white sticky top-0 z-50 border-b border-gray-100">
        <nav className="flex font-bricolage flex-row items-center justify-between p-5 max-w-7xl mx-auto">
          <div className="flex flex-row items-center gap-16">
            <div className="logo">
              {/* <Logo /> */}
              <Link to="/">
                <img src={Logo} alt="Logo" className="w-[95px] h-4" />
              </Link>
            </div>
            <ul className="md:flex hidden flex-row items-center gap-5 ">
              {/* <li>
              <a href="#" className="font-bricolage">
                Home
              </a>
            </li> */}
              <li>
                <Link to="/stories">Stories</Link>
              </li>
              <li>
                <Link to="/videos">Videos</Link>
              </li>
              <li>
                <Link
                  to="/share"
                  className="bg-[#1E1E1E] font-medium text-base text-white rounded-lg px-5 py-2"
                >
                  Share your story
                </Link>
              </li>
            </ul>
          </div>
          {!user && !isLoggedIn && (
            <div className="md:flex hidden flex-row gap-3 items-center">
              {/* <Link to="/profile">My profile</Link> */}
              <Link to="/auth/login">Login</Link>
              <Link to="/auth/signup">Signup</Link>
              {/* <Link
              to="/share"
              className="bg-[#1E1E1E] font-medium text-base text-white rounded-lg px-5 py-2"
            >
              Share your story
            </Link> */}
            </div>
          )}
          <button
            className="md:hidden flex flex-col gap-1"
            onClick={() => {
              mainStore.toggleSidebar();
            }}
          >
            <span className="w-6 rounded h-1 bg-black block"></span>
            <span className="w-6 rounded h-1 bg-black block"></span>
            <span className="w-6 rounded h-1 bg-black block"></span>
          </button>
        </nav>
      </header>

      <div
        className={`${
          isSidebarOpened ? "opacity-100 visible" : "opacity-0 invisible"
        } fixed w-full h-full z-[99] bottom-0 bg-[#0000004f] cursor-pointer transition-all duration-200`}
        onClick={() => {
          mainStore.toggleSidebar(false);
        }}
      >
        <section
          className={`h-[100vh] z-[99999] transition-all duration-200 fixed right-0 top-0 bottom-0 translate-x-[100%] ${
            isSidebarOpened ? "!translate-x-0" : ""
          }`}
        >
          <Sidebar />
        </section>
      </div>
    </>
  );
};

export default Header;
