import React from "react";
import Header from "../header";
import { toast } from "react-toastify";
import axios from "../../service/axios";
import useAuthStore from "../../stores/auth";

const Profile = () => {
  const { user } = useAuthStore();
  const [formData, setFormData] = React.useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (
        !formData?.oldPassword?.trim() ||
        !formData?.password?.trim() ||
        !formData?.confirmPassword?.trim()
      ) {
        return toast.error("All fields are required");
      }

      if (formData?.password.length < 6) {
        return toast.error("Password must be at least 6 characters long");
      }

      if (formData?.oldPassword < 6) {
        return toast.error("Invalid old password");
      }

      if (formData?.password !== formData?.confirmPassword) {
        return toast.error("Passwords do not match");
      }

      setIsLoading(true);
      const { data } = await axios.post("/auth/update-password", formData);

      if (data?.status !== "success") {
        return toast.error(data?.message);
      }

      toast.success(data?.message || "Password changed successfully");
      setFormData({
        oldPassword: "",
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Header />
      <main className="">
        <div>
          <div className="max-w-7xl mx-auto p-5">
            <h2 className="font-medium text-3xl font-bricolage">
              Manage your account information here.
            </h2>
            <div className="flex flex-col items-center w-full mt-5">
              <label
                htmlFor="email"
                className="w-full font-medium text-sm font-bricolage"
              >
                Email
              </label>
              <div className="w-full">
                <input
                  type="email"
                  id="email"
                  name="email"
                  readOnly
                  value={user?.email}
                  className="border-gray-300 border text-base rounded-lg px-3 py-2 w-full outline-none"
                />
              </div>
            </div>
            <div className="border-t border-gray-200 pt-5 mt-10">
              <div>
                <h4 className="font-bricolage text-lg text-gray-900">
                  Password
                </h4>
                <p className="text-gray-500 text-sm font-bricolage">
                  Please enter your current password to change your password.
                </p>
                <form
                  onSubmit={handleSubmit}
                  className="mt-5 flex flex-col gap-1 w-full"
                >
                  <div className="flex flex-col items-center w-full">
                    <label
                      htmlFor="oldPassword"
                      className="w-full font-medium text-sm font-bricolage"
                    >
                      Old Password
                    </label>
                    <div className="w-full">
                      <input
                        type="password"
                        id="oldPassword"
                        onChange={handleChange}
                        name="oldPassword"
                        value={formData?.oldPassword}
                        className="border-gray-300 border text-base rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center w-full">
                    <label
                      htmlFor="password"
                      className="w-full font-medium text-sm font-bricolage"
                    >
                      New Password
                    </label>
                    <div className="w-full">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        onChange={handleChange}
                        value={formData?.password}
                        className="border-gray-300 border text-base rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center w-full">
                    <label
                      htmlFor="confirmPassword"
                      className="w-full font-medium text-sm font-bricolage"
                    >
                      Repeat New Password
                    </label>
                    <div className="w-full">
                      <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={handleChange}
                        value={formData?.confirmPassword}
                        className="border-gray-300 border text-base rounded-lg px-3 py-2 w-full outline-none"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end mt-2">
                    <button
                      disabled={
                        isLoading ||
                        !formData?.password?.trim() ||
                        !formData?.confirmPassword?.trim() ||
                        !formData?.oldPassword?.trim()
                      }
                      className="bg-black text-white px-5 py-2 text-base rounded-lg border border-black md:w-auto w-full disabled:opacity-70 cursor-pointer disabled:cursor-not-allowed"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Profile;
