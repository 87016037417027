// authStore.js
import { create } from "zustand";
import axios from "../service/axios";

const useAuthStore = create(function (set) {
  return {
    user: null,
    setUser: (user) => set({ user }),
    removeUser: () => set({ user: null }),
    isGettingUser: false,
    setIsGettingUser: (isGettingUser) => set({ isGettingUser }),
    fetchedInitialUser: false,
    setFetchedInitialUser: (fetchedInitialUser) => set({ fetchedInitialUser }),
    isFetchingInitialUser: false,
    isLoggedIn: false,
    getUserDetails: async () => {
      set({ isGettingUser: true });

      if (!useAuthStore.getState().fetchedInitialUser) {
        set({ isFetchingInitialUser: true });
      }

      try {
        const { data } = await axios.get("/user");
        if (data?.status === "success") {
          set({ user: data.data });
          set({ isLoggedIn: true });
        }
      } catch (error) {
        console.log(error);
      }

      set({ isGettingUser: false });

      if (!useAuthStore.getState().fetchedInitialUser) {
        set({ fetchedInitialUser: true, isFetchingInitialUser: false });
      }
    },
    setIsFetchingInitialUser: (isFetchingInitialUser) =>
      set({ isFetchingInitialUser }),
  };
});

export default useAuthStore;
