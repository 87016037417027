import React, { useEffect } from "react";
import "./src/styles/global.css";
import useAuthStore from "./src/stores/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Router } from "@reach/router";
import Login from "./src/components/views/auth/login";
import Profile from "./src/components/views/profile";
// import {init} from "gatsby"

const AppLayout = ({ children }) => {
  // const { user, getUserDetails } = useAuthStore();

  // useEffect(() => {
  //   if (!user) {
  //     getUserDetails();
  //   }
  // }, []);

  return (
    <>
      <ToastContainer />
      {children}
    </>
  );
};

export const wrapRootElement = ({ element }) => {
  return <AppLayout>{element}</AppLayout>;
};
